/**
 * VENDOR
 */
import Alpine from 'alpinejs';

/**
 * APP
 */
import './modules/bootstrap'
import './modules/main'

/**
 * Scripts
 */
window.Alpine = Alpine;
Alpine.start();
