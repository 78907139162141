import $ from 'jquery';
import selectize from '@selectize/selectize'
import select2 from 'select2'
import slick from 'slick-carousel'
import Smarquee from 'jquery.simplemarquee';

// temp for console debug
// window.jQuery = $;
// window.$ = $;
// window.jQuery = $;

function maskPhone(selector, masked = '+7 (___) ___-__-__') {
	const elems = document.querySelectorAll(selector);

	function mask(event) {
		const keyCode = event.keyCode;
		const template = masked,
			def = template.replace(/\D/g, ""),
			val = this.value.replace(/\D/g, "");
		console.log(template);
		let i = 0,
			newValue = template.replace(/[_\d]/g, function (a) {
				return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
			});
		i = newValue.indexOf("_");
		if (i !== -1) {
			newValue = newValue.slice(0, i);
		}
		let reg = template.substr(0, this.value.length).replace(/_+/g,
			function (a) {
				return "\\d{1," + a.length + "}";
			}).replace(/[+()]/g, "\\$&");
		reg = new RegExp("^" + reg + "$");
		if (!reg.test(this.value) || this.value.length < 5 || keyCode > 47 && keyCode < 58) {
			this.value = newValue;
		}
		if (event.type === "blur" && this.value.length < 5) {
			this.value = "";
		}

	}

	for (const elem of elems) {
		elem.addEventListener("input", mask);
		elem.addEventListener("focus", mask);
		elem.addEventListener("blur", mask);
	}

}

document.addEventListener('DOMContentLoaded', function () {
	maskPhone('input[type=tel]')
})

let isReadyYT = !($('div').is('.youtube-js') && $('.youtube-js').length > 0);
let isLoad = 0;

const isSafari =
	/constructor/i.test(window.HTMLElement) ||
	(function (p) {
		return p.toString() === '[object SafariRemoteNotification]'
	})(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

if (isSafari) {
	document.body.classList.add('safari')
}

const checkLoad = () => {
	if (isLoad === 3 && $('div').is('#preloader')) $('#preloader').fadeOut(300);
};

$(window).on('load', () => {
	isLoad = isLoad + 1;
	checkLoad();
});

setTimeout(() => {
	isLoad = isLoad + 1;
	checkLoad();
}, 3000);

const headerControl = () => {
	const header = $('#header')

	if ($(window).scrollTop() > 15) {
		if (!$(header).hasClass('white')) $(header).addClass('white')
	} else {
		if ($(header).hasClass('white')) $(header).removeClass('white')
	}
}

$(document).ready(function () {
	const select = $('select')
	const div = $('div')
	const svg = $('svg')
	const elementByTagP = $('p')
	const elementByTagG = $('g')
	const elementByTagSection = $('section')

	if ($('*').is('#header')) {
		headerControl()

		$(window).on('scroll resize', headerControl)
	}

	if ($(div).is('#menuOpen')) {
		const clearWorkSpace = () => {
			$('#menu').removeClass('show')
			$('#menuOpen').removeClass('show')
			$('body').removeClass('no-scroll')
			$('body').removeClass('menu-mob-open')
		}

		$(window).on('resize', clearWorkSpace)

		$('#menuOpen').on('click', () => {
			$('#menu').toggleClass('show')
			$('#menuOpen').toggleClass('show')
			$('body').toggleClass('no-scroll')
			$('body').toggleClass('menu-mob-open')
		})

		document.querySelectorAll('.link').forEach(item => {
			item.addEventListener('click', function () {
				console.log("1")
				if ($('#menu').hasClass('show')) {
					console.log("2")
					$('#menu').toggleClass('show')
					$('#menuOpen').toggleClass('show')
					$('body').toggleClass('no-scroll')
					$('body').toggleClass('menu-mob-open')
				}
			})
		})
		// $('#menuClose').on('click', clearWorkSpace)

		// $('a[href^="#"]:not([href="#"]), a[href^="/#"]:not([href="/#"])').on('click', function (event) {
		//     event.preventDefault()
		//     const id = $(this).attr('href').replace('/', '')
		//     if ($('*').is(`${id}`)) {
		//         const top = $(id).offset().top - 100;
		//         clearWorkSpace()
		//         $('body,html').animate({scrollTop: top}, 0)
		//     } else {
		//         window.location.assign($(this).attr('href'))
		//     }
		// })
	}

	if ($(div).is('#simplemarquee')) {
		$('#simplemarquee').simplemarquee();
	}

	function changeTab(numberTab, tabContainer) {
		const tabIndex = tabContainer ? '1' : ''
		$(`.tabsblock__tab${tabIndex}`).removeClass('active')
		$(`.tabsblock__page${tabIndex}`).removeClass('active')

		$(`.tabsblock__tab${tabIndex}[data-tab=${numberTab}]`).addClass('active')
		$(`.tabsblock__page${tabIndex}[data-page=${numberTab}]`).addClass('active')
	}

	let { hash } = window.location


	if ($(div).is('#youtubeSlider')) {
		const startSlideYt = () => {
			if (isReadyYT) {
				$('#youtubeSlider').slick({
					dots: true,
					arrows: false,
					autoplay: false,
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
				})
			} else {
				setTimeout(startSlideYt, 1000)
			}
		}
		startSlideYt()
	}

	if ($(div).is('#youtubeSlider2')) {
		const startSlideYt = () => {
			if (isReadyYT) {
				$('#youtubeSlider2').slick({
					dots: true,
					arrows: false,
					autoplay: false,
					adaptiveHeight: true,
					infinite: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					swipe: true,
					touchMove: true,
					// prevArrow: `<button type="button" class="opacity-hover slick-prev"></button>`,
					// nextArrow: `<button type="button" class="opacity-hover slick-next"></button>`,
					responsive: [
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 1,
							},
						},
					],
				})
			} else {
				setTimeout(startSlideYt, 1000)
			}
		}
		startSlideYt()
	}

	let isDragging = false;

	$('#youtubeSlider2').on('touchstart', () => {
		isDragging = true;
	});

	$('#youtubeSlider2').on('touchend', () => {
		isDragging = false;
	});

	if ($(div).is('#youtubeSlider3')) {
		const startSlideYt = () => {

			if (isReadyYT) {
				$('#youtubeSlider3').slick({
					dots: true,
					arrows: false,
					autoplay: false,
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					adaptiveHeight: true
				})
			} else {
				setTimeout(startSlideYt, 1000)
			}
		}
		startSlideYt()
	}

	if ($(div).is('#newsSlider')) {
		$('#newsSlider').slick({
			dots: false,
			arrows: true,
			autoplay: false,
			adaptiveHeight: true,
			infinite: true,
			slidesToShow: 2,
			slidesToScroll: 1,
			prevArrow: `<button type="button" class="opacity-hover slick-prev"></button>`,
			nextArrow: `<button type="button" class="opacity-hover slick-next"></button>`,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		})
	}

	const debounce = function (fn, time) {
		let timeout;

		return function () {
			let self = this;
			const functionCall = function () {
				return fn.apply(self, arguments);
			};
			clearTimeout(timeout);
			timeout = setTimeout(functionCall, time);
		}
	};

	function updateSpacing() {
		let vnewsContainer = $('#newsTinkerList');
		let vnewsContainerHeight = vnewsContainer.outerHeight(true);

		let rowThinker = $('#rowThinker');
		let rowThinkerHeight = rowThinker.outerHeight(true);

		let vnewsColumn = document.querySelector('#vnewsColumn');

		if (vnewsColumn) {
			let computedStyle = window.getComputedStyle(vnewsColumn);
			let flexBasis = computedStyle.getPropertyValue('flex-basis');

			if (flexBasis === '50%') {
				$('#atom').css('margin-top', vnewsContainerHeight - rowThinkerHeight);
			} else {
				$('#atom').css('margin-top', vnewsContainerHeight);
			}
		}
	}

	function setThinkerTitleColumnHeight() {
		const vnewsColumn = document.querySelector('#newsTinkerList');
		const thinkerTitleColumn = document.querySelector('.column_thinker-title');

		if ($('#newsTinkerList').length) {
			if (window.innerWidth > 1024) {
				const vnewsColumnHeight = vnewsColumn.offsetHeight;

				thinkerTitleColumn.style.height = `${vnewsColumnHeight}px`;
			} else {
				thinkerTitleColumn.style.height = '';
			}
		}
	}

	window.addEventListener('load', setThinkerTitleColumnHeight);

	window.addEventListener('resize', setThinkerTitleColumnHeight);

	const debouncedUpdateSpacing = debounce(updateSpacing, 400);

	$(window).on('resize', function () {
		if ($('#newsTinkerList').length) {
			debouncedUpdateSpacing();
		}
	});

	if ($('#newsTinkerList').length) {
		let container = $('#newsTinkerList');

		container.on('click', '.vnews__text', function () {
			let activeSlide = container.find('.slide.active');
			let clickedSlide = $(this).closest('.slide');

			if (clickedSlide.hasClass('active')) {
				return;
			}

			if (clickedSlide.is(':nth-child(3)')) {
				let slidesToHide = container.find('.slide:lt(2)');

				slidesToHide.find('.vnews__text, .vnews__image').slideUp(300, function () {
					slidesToHide.appendTo(container);
					slidesToHide.find('.vnews__text').slideDown(300);
				});

				activeSlide.removeClass('active');
				clickedSlide.addClass('active');
				clickedSlide.find('.vnews__image').hide().slideDown(300);

			} else {

				let slidesToHide = container.find('.slide:lt(1)');

				slidesToHide.find('.vnews__text, .vnews__image').slideUp(300, function () {
					slidesToHide.appendTo(container);
					slidesToHide.find('.vnews__text').slideDown(300);
				});

				activeSlide.removeClass('active');
				clickedSlide.addClass('active');
				clickedSlide.find('.vnews__image').hide().slideDown(300);
			}
		});
	}

	if ($(div).is('#agendaSlider')) {
		$('#agendaSlider').slick({
			dots: false,
			arrows: true,
			autoplay: false,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			// adaptiveHeight: false,
			prevArrow: `<button type="button" class="opacity-hover slick-prev"></button>`,
			nextArrow: `<button type="button" class="opacity-hover slick-next"></button>`,
			rows: 1,
			responsive: [
				{
					breakpoint: 1180,
					settings: { slidesToShow: 2 },
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						rows: 1,
					},
				},
			],
		})
	}

	if ($(div).is('#projectsSlide')) {
		$('#projectsSlide').slick({
			dots: false,
			arrows: true,
			autoplay: false,
			adaptiveHeight: true,
			infinite: true,
			slidesToShow: 2,
			slidesToScroll: 1,
			prevArrow: `<button type="button" class="opacity-hover slick-prev"></button>`,
			nextArrow: `<button type="button" class="opacity-hover slick-next"></button>`,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					},
				},
				{
					breakpoint: 1080,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
			],
		})
	}

	if ($(div).is('#reviewsSlider')) {
		$('#reviewsSlider').slick({
			dots: false,
			arrows: true,
			autoplay: false,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			prevArrow: `<button type="button" class="opacity-hover slick-prev"></button>`,
			nextArrow: `<button type="button" class="opacity-hover slick-next"></button>`,
			rows: 2,
			responsive: [
				{
					breakpoint: 950,
					settings: { slidesToShow: 2 },
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						rows: 1,
					},
				},
			],
		})
	}

	if ($(div).is('#new_quizzes-slider') && window.innerWidth <= 930) {
		$('#new_quizzes-slider').slick({
			dots: false,
			arrows: true,
			autoplay: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow: `<button type="button" class="opacity-hover slick-prev"></button>`,
			nextArrow: `<button type="button" class="opacity-hover slick-next"></button>`,
			rows: 1,
		})
	}
	if ($(div).is('#old_quizzes-slider') && window.innerWidth <= 930) {
		$('#old_quizzes-slider').slick({
			dots: false,
			arrows: true,
			autoplay: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow: `<button type="button" class="opacity-hover slick-prev"></button>`,
			nextArrow: `<button type="button" class="opacity-hover slick-next"></button>`,
			rows: 1,
		})
	}

	if ($(div).is('#accord')) {
		$('#accord .faq__item').on('click', function () {

			let accordFaqItem = $('#accord .faq__item')
			accordFaqItem.not($(this)).find('.faq__content').slideUp(500)
			accordFaqItem.not($(this)).removeClass('active')
			$(this).toggleClass('active')
			$(this).find('.faq__content').slideToggle(500)
		})
	}
	// if ($(div).is('.pop-up-blur')) {
	//     let seeTheGreetings = document.cookie.replace(
	//         /(?:(?:^|.*;\s*)seeTheGreetings\s*\=\s*([^;]*).*$)|^.*$/,
	//         "$1",
	//     );
	//     if (!seeTheGreetings) {
	//         $('.pop-up-blur').css('display', 'flex')
	//         $('.greetings').css('display', 'block')
	//     }
	//     $('#greetings-close').click(function () {
	//         document.cookie = 'seeTheGreetings=true; max-age=86400';
	//         $('.pop-up-blur').css('display', 'none')
	//     })
	//     $('#greetings-button').click(function () {
	//         document.cookie = 'seeTheGreetings=true; max-age=86400';
	//         $('.pop-up-blur').css('display', 'none')
	//     })
	// }

	function openAccordion() {
		let accordFaqItem = $('.accordions .accordion-item')
		accordFaqItem.not($(this).parent()).find('.accordion-body').slideUp(500)
		accordFaqItem.not($(this).parent()).removeClass('active')
		$(this).parent().toggleClass('active')
		$(this).next('.accordion-body').slideToggle(500)
	}


	if (hash.includes('#materials-ch') || hash.includes('#materials-l')) {
		let element = $(hash);
		$('.accordions .accordion-header').on('open-after-load', openAccordion);

		element.trigger('open-after-load')
	}

	if ($(div).is('.accordions')) {
		$('.accordions .accordion-header').on('click', openAccordion)
	}

	if ($(div).is('#atomButtonCombos')) {

		document.querySelectorAll('#atomButtonCombos').forEach(function (button) {
			button.addEventListener('click', function () {
				document.querySelectorAll('#atomButtonCombos').forEach(function (btn) {
					btn.classList.remove('active');
				});
				// button.classList.add('active');
			});
		});

		$('#atomButtonLeft').click(function () {
			// $('#atomTabLeft').addClass('active').removeClass('show-tab_atom-right');
			$('#atomButtonRight').removeClass('active');
			$('#atomButtonLeft').addClass('active');
			$('#atomTabRight').removeClass('active');
			$('#atomTabLeft').addClass('active');
			$('#atomTabLeft').addClass('active');
			$('#atomTabCards').addClass('active');
			// $('#atomTabLeft').addClass('active');
			// $('#atomTabRight').removeClass('active');
		});

		$('#atomButtonRight').click(function () {
			$('#atomButtonLeft').removeClass('active');
			$('#atomButtonRight').addClass('active');
			$('#atomTabLeft').removeClass('active');
			$('#atomTabRight').addClass('active');
			$('#atomTabCards').removeClass('active');
			// $('.tab-cards').addClass('show-tab_atom-right').removeClass('show-tab_atom-left');
			// $('#tabButtonRight').addClass('active');
			// $('#tabButtonLeft').removeClass('active');
			// $('#atomTabRight').addClass('active');
			// $('#atomTabLeft').removeClass('active');
		});

	}

	if ($(div).is('#historyButtonCombos')) {

		// $('#historyTabRight').removeClass('visible').addClass('hidden');
		// $('#historyTabLeft').removeClass('hidden').addClass('visible');
		// $('#historyTabRight').removeClass('visible').css('opacity', 0);
		// $('#historyTabLeft').addClass('visible').css('opacity', 1);

		document.querySelectorAll('#historyButtonCombos').forEach(function (button) {
			button.addEventListener('click', function () {
				document.querySelectorAll('#historyButtonCombos').forEach(function (btn) {
					btn.classList.remove('active');
				});
				// button.classList.add('active');
			});
		});

		$('#historyButtonLeft').click(function () {
			$('#historyButtonRight').removeClass('active');
			$('#historyButtonLeft').addClass('active');
			$('#historyTabRight').removeClass('active');
			$('#historyTabLeft').addClass('active');
			// $('#historyTabLeft').addClass('active');
			$('#historyTabCards').addClass('active');

			// $('#historyTabRight').removeClass('visible').addClass('hidden');
			// $('#historyTabLeft').removeClass('hidden').addClass('visible');
			$('#historyTabRight').removeClass('visible').css('opacity', 0);
			$('#historyTabLeft').css('visibility', 'visible').addClass('visible').css('opacity', 1);
		});

		$('#historyButtonRight').click(function () {
			$('#historyButtonLeft').removeClass('active');
			$('#historyButtonRight').addClass('active');
			$('#historyTabLeft').removeClass('active');
			$('#historyTabRight').addClass('active');
			$('#historyTabCards').removeClass('active');

			// $('#historyTabRight').removeClass('hidden').addClass('visible');
			// $('#historyTabLeft').removeClass('visible').addClass('hidden');
			$('#historyTabLeft').removeClass('visible').css('opacity', 0);
			$('#historyTabRight').css('visibility', 'visible').addClass('visible').css('opacity', 1);
		});

	}

	$(function () {
		$('#teacher').click(function () {
			$('#stageStudent').css('display', 'none').slideUp(500)
			$('#stageTeacher').css('display', 'block').slideDown(500)
			$('#teacher').addClass('active')
			$('#student').removeClass('active-blue')
		})
		$('#student').click(function () {
			$('#stageStudent').css('display', 'block').slideDown(500)
			$('#stageTeacher').css('display', 'none').slideUp(500)
			$('#student').addClass('active-blue')
			$('#teacher').removeClass('active')
		})
	})

	let $select = $("#teacher_find").selectize({
		plugins: ["restore_on_backspace"],
		create: true,
		diacritics: true,
		persist: false,
		maxItems: 1,
		openOnFocus: true,
		showAddOptionOnCreate: false,
		closeAfterSelect: true,
		valueField: "id",
		labelField: "name",
		searchField: "name",
		loadingClass: 'field_input',
		placeholder: 'Учитель',
		createOnBlur: false,

	})[0]

	if ($select) {

		setDefaultOptions()
	}

	function setDefaultOptions() {
		const API_URL = document.location.host
		const protocol = document.location.protocol

		axios.get(`${protocol}//${API_URL}/api/v1/teachers/find/А`).then((response) => {
			const teachers = response.data;

			// $('.selectize-dropdown-content').empty()
			for (let teacherId in teachers) {
				$('.selectize-dropdown-content').append(`<div class="option active" data-selectable data-value="${teacherId}">${teachers[teacherId]}</div>`)
				$select.selectize.addOption({
					name: teachers[teacherId],
					id: teacherId
				});
			}
		});
	}

	async function findTeachers($select) {
		const API_URL = document.location.host
		const protocol = document.location.protocol

		return await axios.get(`${protocol}//${API_URL}/api/v1/teachers/find/${$select.selectize.currentResults.query}`)
	}

	function changeOptions() {
		findTeachers($select).then((response) => {
			const teachers = response.data;
			// $select.selectize.open()

			// $('.selectize-dropdown-content').empty()
			for (let teacherId in teachers) {
				$('.selectize-dropdown-content').append(`<div class="option active" data-selectable data-value="${teacherId}">${teachers[teacherId]}</div>`)
				$select.selectize.addOption({
					name: teachers[teacherId],
					id: teacherId
				});
			}
			queueMicrotask(() => {
				$('.plugin-restore_on_backspace').css('display', 'block')

			})
		});
	}

	// $('#teacher_find-selectized').on('input', function () {
	//
	//     $select.selectize.clearOptions()
	//
	//     if ($select.selectize.currentResults.query !== '') {
	//         changeOptions()
	//     }
	// })
	$('#teacher_find-selectized').on('input', debounce(function () {
		// $select.selectize.clearOptions()

		if ($select.selectize.currentResults.query !== '') {
			changeOptions()
		}
	}, 500))

	$(function () {
		$('.register-sso-tab-teacher').click(function () {
			$('.register-form-student').css('display', 'none')
			$('.register-form-teacher').css('display', 'grid')
			$('.register-sso-tab-teacher').addClass('register-sso-tab-teacher-active')
			$('.register-sso-tab-student').removeClass('register-sso-tab-student-active')

		})
		$('.register-sso-tab-student').click(function () {
			$('.register-form-student').css('display', 'grid')
			$('.register-form-teacher').css('display', 'none')

			$('.register-sso-tab-student').addClass('register-sso-tab-student-active')
			$('.register-sso-tab-teacher').removeClass('register-sso-tab-teacher-active')
		})
	})

	if ($(div).is('#cookie')) {
		let acceptCookie = document.cookie.replace(
			/(?:(?:^|.*;\s*)acceptCookie\s*\=\s*([^;]*).*$)|^.*$/,
			"$1",
		);
		if (!acceptCookie) {
			$('#cookie').css('display', 'flex')
		}
		$('#accept').click(function () {
			document.cookie = 'acceptCookie=true; max-age=86400';
			$('#cookie').css('display', 'none')
		})
	}
	document.body.addEventListener('select2Init', (e) => {
		if ($(select).is('.formSel-js')) {
			$('.formSel-js').select2({
				minimumResultsForSearch: -1,
				theme: 'selectWrap__select',
			})
		}
	})
	const event = new Event('select2Init');
	document.body.dispatchEvent(event)

	$("#lesson_id").val($("#lesson_id option:first").val());

	$('.dark-wrapper')
		.on('click', () => {
			document.querySelectorAll('.dark').forEach(modal => {
				if (modal.style.display === 'block') {
					setModalToCookie(modal)
				}
			})
			$('.dark').fadeOut(0)
			let youtubeModalIframe = $('#youtube-modal iframe')[0] || null
			if (youtubeModalIframe !== null) {
				youtubeModalIframe.src = ''
			}
			$('#modals-wrapper').css('z-index', -1)
			$('body').css('overflow', '')
		})
		.children()
		.on('click', (e) => {
			e.stopPropagation()
		})

	document.body.addEventListener('signinButtonsInit', (e) => {
		if ($(div).is('.open-login-form-btn') && $(div).is('#singin')) {
			$('.open-login-form-btn').on('click', () => {
				$('.dark').fadeOut(0)
				$('#modals-wrapper').css('z-index', 28)
				$('#singin').fadeIn(0)
				$('body').css('overflow', 'hidden')
			})
		}

		if ((elementByTagP.is('#reg') || $('*').is('.regbut-js').length) && $(div).is('#regform')) {
			$('#reg, .regbut-js, .open-register-form-btn').on('click', () => {
				$('.dark').fadeOut(0)
				$('#modals-wrapper').css('z-index', 28)
				$('#regform').fadeIn(0)
				$('body').css('overflow', 'hidden')
			})
		}
	})
	document.body.dispatchEvent(new Event('signinButtonsInit'))

	function showOnClick(showElementSelector, clickElementSelectorName) {
		if ($(div).is(showElementSelector)) {
			$(clickElementSelectorName).on('click', () => {
				$('.dark').fadeOut(0)
				$('#modals-wrapper').css('z-index', 28)
				$(showElementSelector).fadeIn(0)
				$('body').css('overflow', 'hidden')
			})
		}
	}

	let showOnClickElementSelectorList = [
		{
			showElementSelector: '#forgotpassword',
			clickElementSelector: '#goforgotpassword'
		},
		{
			showElementSelector: '#teacheredit',
			clickElementSelector: '#open_account_teacher3'
		},
		{
			showElementSelector: '#studentedit',
			clickElementSelector: '#goeditstudent'
		},
		{
			showElementSelector: '#regteacher',
			clickElementSelector: '#goregteacher'
		},
		{
			showElementSelector: '#regstudent',
			clickElementSelector: '#goregstudent'
		},
		{
			showElementSelector: '#regteachervk',
			clickElementSelector: '#goregteachervk'
		},
		{
			showElementSelector: '#regstudentvk',
			clickElementSelector: '#goregstudentvk'
		},
		{
			showElementSelector: '#reviewform',
			clickElementSelector: '#goreviewform'
		},
		{
			showElementSelector: '#feedbackform',
			clickElementSelector: '#gofeedbackformfooter1'
		},
		{
			showElementSelector: '#loginformvk',
			clickElementSelector: '#signInByVkButton'
		},
		{
			showElementSelector: '#userprofile',
			clickElementSelector: '#getprofile'
		},
		{
			showElementSelector: '#userprofile',
			clickElementSelector: '#map-open-profile'
		},
		{
			showElementSelector: '#swappassword',
			clickElementSelector: '#goswappasswordteacher'
		},
		{
			showElementSelector: '#swappassword',
			clickElementSelector: '#goswappasswordstudent'
		},
		{
			showElementSelector: '#userprofile',
			clickElementSelector: '#getprofile_mobule'
		},
		{
			showElementSelector: '#reportform',
			clickElementSelector: '#goreportform'
		},
		// {
		//     showElementSelector: '#userprofile',
		//     clickElementSelector: '#goaccauntteacher'
		// },
		{
			showElementSelector: '#userprofile',
			clickElementSelector: '#mobile_userprofile'
		},
	]

	showOnClickElementSelectorList.map((showOnClickElementSelector) => {
		showOnClick(showOnClickElementSelector.showElementSelector, showOnClickElementSelector.clickElementSelector)
	})

	if ($(div).is('#feedbackform')) {
		$('#gofeedbackformfooter2').on('click', () => {
			$('.dark').fadeOut(0)
			$('#modals-wrapper').css('z-index', 28)
			let selector = document.getElementById('feedbackform').querySelector('select[name=callback_type_id]')
			selector.value = 1
			selector.dispatchEvent(new Event('change'))
			$('#feedbackform').fadeIn(0)
		})

		$('#gofeedbackformfooter3').on('click', () => {
			$('.dark').fadeOut(0)
			$('#modals-wrapper').css('z-index', 28)
			let selector = document.getElementById('feedbackform').querySelector('select[name=callback_type_id]')
			selector.value = 5
			selector.dispatchEvent(new Event('change'))
			$('#feedbackform').fadeIn(0)
		})
		$('.btn-change-role').on('click', () => {
			$('.dark').fadeOut(0)
			$('#modals-wrapper').css('z-index', 28)
			let selector = document.getElementById('feedbackform').querySelector('select[name=callback_type_id]')
			selector.value = 7
			selector.dispatchEvent(new Event('change'))
			$('#feedbackform').fadeIn(0)
		})
	}

	function youtubeModalAction(link) {
		$('.dark').fadeOut(0)
		$('#modals-wrapper').css('z-index', 28)
		$('#youtube-modal').fadeIn(0)
		let youtubeModalIframe = $('#youtube-modal iframe')[0]
		youtubeModalIframe.src = link
		youtubeModalIframe.width = window.innerWidth * 0.8
		youtubeModalIframe.height = ((window.innerWidth * 0.8) / 16) * 9
	}

	document.querySelectorAll('.js-open-video').forEach(button => {
		button.addEventListener('click', function () {
			const link = button.getAttribute('data-link')
			youtubeModalAction(link)
			$('body').css('overflow', 'hidden')
		})
	})
	if ($(div).is('.youtube-open__container') && $(div).is('#youtube-modal')) {
		$('.youtube-open__container').on('click', (e) => {
			if (!isDragging) {
				// const link = e.target.querySelector('.youtube-open__item').getAttribute('data-link')
				const link = e.currentTarget.querySelector('.youtube-open__item').getAttribute('data-link')
				youtubeModalAction(link)
				$('body').css('overflow', 'hidden')
			}
		})

		$('.youtube-open__item').on('click', (e) => {
			console.log(1)
			const link = e.target.getAttribute('data-link')
			youtubeModalAction(link)
			$('body').css('overflow', 'hidden')
		})
	}

	if ($(div).is('.formClose-js') && $('.formClose-js').length) {
		$('.formClose-js').on('click', function () {
			document.querySelectorAll('.dark').forEach(modal => {
				if (modal.style.display === 'block') {
					setModalToCookie(modal)
				}
			})
			let youtubeModalIframe = $('#youtube-modal iframe')[0] || null
			if (youtubeModalIframe !== null) {
				youtubeModalIframe.src = ''
			}
			$('.dark').fadeOut(0);
			$('#modals-wrapper').css('z-index', '');
			$('body').css('overflow', '')
		});
	}

	if (elementByTagG.is('#svgmobleftbutton') && svg.is('#svgmobright') && svg.is('#svgmobleft') && elementByTagG.is('#svgmobleftbutton') && elementByTagG.is('#svgmobrightbutton')) {
		$('#svgmobleftbutton').on('click', function () {
			$('#svgmobleft').slideUp(300);
			$('#svgmobright').slideDown(300);
		});

		$('#svgmobrightbutton').on('click', function () {
			$('#svgmobright').slideUp(300);
			$('#svgmobleft').slideDown(300);
		});
	}

	if (elementByTagG.is('.getConcurs') && elementByTagSection.is('#materials')) {
		$('.getConcurs').on('click', function () {
			const top = $('#materials').offset().top - 100;
			$('body,html').animate({ scrollTop: top }, 0);
		});
	}

	if (elementByTagG.is('.getVictorins') && elementByTagSection.is('#video')) {
		$('.getVictorins').on('click', function () {
			const top = $('#video').offset().top - 100;
			$('body,html').animate({ scrollTop: top }, 0);
		});
	}


	$(document).ready(function () {
		$.ajaxSetup({
			prefix: window.location.origin,
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});
		let ajaxSSOForm = function () {
			const form = $(this);
			const formData = new FormData(this);
			const method = $(this).attr('method');
			const action = $(this).attr('action');
			const target = $(this).data('target');
			const command = $(this).data('command');
			const onSuccess = $(this).data('onSuccess');
			const submitButton = form.find('button')[0] || null;
			const inputWrappers = form.find('.form__fieldWrap')

			if (submitButton !== null) {
				submitButton.classList.add('loading')
				submitButton.disabled = true
			}

			// console.log('ajax:' + method + ' — ' + action);
			$.ajax({
				type: method,
				url: action,
				cache: false,
				processData: false,
				contentType: false,
				data: formData,
				async: true,
				success: function (data) {
					window.location.reload()
					window.location.replace('/')
					console.log('redirect')
				},
				error: function (jqXHR, textStatus, errorThrown) {
					if (jqXHR && jqXHR.hasOwnProperty('responseJSON')) {
						let response = jqXHR.responseJSON
						if (response && response.errors) {
							let errors = response.errors
							inputWrappers.each((key, inputWrapper) => {
								let input = inputWrapper.getElementsByClassName('interactive')[0] || null
								if (input !== null) {
									let validateErrors = errors[input.getAttribute('name')] || null
									$(inputWrapper).find('span.form__label').each(function (validateSpanKey, validateSpan) {
										validateSpan.remove()
									})
									if (validateErrors !== null && validateErrors.length > 0) {
										inputWrapper.classList.add("error")

										validateErrors.forEach(function (inputErrorValue, inputErrorKey) {
											let validateSpan = document.createElement('span')
											validateSpan.className = "form__label";
											validateSpan.innerText = inputErrorValue

											inputWrapper.append(validateSpan)
										})
									} else {
										inputWrapper.classList.remove("error")
									}
								}
							})
						}
					}
				},
				complete: function (data) {
					if (submitButton !== null) {
						submitButton.classList.remove('loading')
						submitButton.disabled = false
					}
				}
			})
			return false
		}

		function initAjaxSSOForm() {
			$('.js-on-submit-ajax-SSO-form').not('.js-on-submit-ajax-SSO-form-inited').addClass('js-on-submit-ajax-SSO-form-inited').on('submit', ajaxSSOForm);
		}

		initAjaxSSOForm()
		let ajaxFirstStep = function () {
			const form = $(this);
			const formData = new FormData(this);
			const method = $(this).attr('method');
			const action = $(this).attr('action');
			const target = $(this).data('target');
			const command = $(this).data('command');
			const onSuccess = $(this).data('onSuccess');
			const submitButton = form.find('button')[0] || null;
			const inputWrappers = form.find('.form__fieldWrap')

			if (submitButton !== null) {
				submitButton.classList.add('loading')
				submitButton.disabled = true
			}

			// console.log('ajax:' + method + ' — ' + action);
			$.ajax({
				type: method,
				url: action,
				cache: false,
				processData: false,
				contentType: false,
				data: formData,
				async: true,
				success: function (data) {
					$('#slideReview1').css('display', 'none')
					$('#slideReview2').css('display', 'block')
				},
				error: function (jqXHR, textStatus, errorThrown) {
					if (jqXHR && jqXHR.hasOwnProperty('responseJSON')) {
						let response = jqXHR.responseJSON
						if (response && response.errors) {
							let errors = response.errors
							inputWrappers.each((key, inputWrapper) => {
								let input = inputWrapper.getElementsByClassName('interactive')[0] || null
								if (input !== null) {
									let validateErrors = errors[input.getAttribute('name')] || null
									$(inputWrapper).find('span.form__label').each(function (validateSpanKey, validateSpan) {
										validateSpan.remove()
									})
									if (validateErrors !== null && validateErrors.length > 0) {
										inputWrapper.classList.add("error")

										validateErrors.forEach(function (inputErrorValue, inputErrorKey) {
											let validateSpan = document.createElement('span')
											validateSpan.className = "form__label";
											validateSpan.innerText = inputErrorValue

											inputWrapper.append(validateSpan)
										})
									} else {
										inputWrapper.classList.remove("error")
									}
								}
							})
						}
					}
				},
				complete: function (data) {
					if (submitButton !== null) {
						submitButton.classList.remove('loading')
						submitButton.disabled = false
					}
				}
			})
			return false
		}
		document.getElementById('files').addEventListener('click', function () {
			const button = document.getElementsByClassName('form__input-file-label')
			if (this.value) {
				button[0].style['background'] = '#21ba45'
				button[0].innerHTML = 'Фото прикреплено!'
			}
		});
		document.getElementById('lesson_id').addEventListener('change', function () {
			console.log(this, 1321321321)
		})

		function initAjaxFirstStep() {
			$('.js-on-submit-ajax-first-step').not('.js-on-submit-ajax-first-step-inited').addClass('js-on-submit-ajax-first-step-inited').on('submit', ajaxFirstStep);
		}

		initAjaxFirstStep()

		let ajaxWithUpload = function () {
			const form = $(this);
			const formData = new FormData(this);
			const method = $(this).attr('method');
			const action = $(this).attr('action');
			const target = $(this).data('target');
			const command = $(this).data('command');
			const onSuccess = $(this).data('onSuccess');
			const submitButton = form.find('button')[0] || null;
			const inputWrappers = form.find('.form__fieldWrap')

			if (submitButton !== null) {
				submitButton.classList.add('loading')
				submitButton.disabled = true
			}

			// console.log('ajax:' + method + ' — ' + action);
			document.getElementById('files').addEventListener('change', function () {
				if (this.value) {
					const error_label = document.getElementById('error-label-for-button-files')
					if (error_label.innerHTML != null) {
						error_label.style['display'] = 'none'
					}
					const button = document.getElementsByClassName('form__input-file-label')
					button[0].style['background'] = '#21ba45'
				}
			});
			$.ajax({
				type: method,
				url: action,
				cache: false,
				processData: false,
				contentType: false,
				data: formData,
				async: true,
				success: function (data) {
					location.reload()
				},
				error: function (jqXHR, textStatus, errorThrown) {
					if (jqXHR && jqXHR.hasOwnProperty('responseJSON')) {
						let response = jqXHR.responseJSON
						if (response && response.errors) {
							let errors = response.errors
							inputWrappers.each((key, inputWrapper) => {
								let input = inputWrapper.getElementsByClassName('interactive')[0] || null
								if (input !== null) {
									let validateErrors = errors[input.getAttribute('name')] || null
									$(inputWrapper).find('span.form__label').each(function (validateSpanKey, validateSpan) {
										validateSpan.remove()
									})
									if (validateErrors !== null && validateErrors.length > 0) {
										inputWrapper.classList.add("error")

										validateErrors.forEach(function (inputErrorValue, inputErrorKey) {
											let validateSpan = document.createElement('span')
											validateSpan.className = "form__label";
											validateSpan.innerText = inputErrorValue

											inputWrapper.append(validateSpan)
										})
									} else {
										inputWrapper.classList.remove("error")
									}
									if (response.errors && response.errors.files) {
										const error_label = document.getElementById('error-label-for-button-files')
										error_label.style['color'] = '#FE0000'
										error_label.style['font-size'] = '14px'
										error_label.innerHTML = response.errors.files[0]
									}
								}
							})
						}
					}
				},
				complete: function (data) {
					if (submitButton !== null) {
						submitButton.classList.remove('loading')
						submitButton.disabled = false
					}
				}
			})
			return false
		}

		function initAjaxWithUpload() {
			$('.js-on-submit-ajax-with-uploads').not('.js-on-submit-ajax-with-uploads-inited').addClass('js-on-submit-ajax-with-uploads-inited').on('submit', ajaxWithUpload);
		}

		initAjaxWithUpload()

		let ajax = function () {
			const form = $(this);
			const formData = $(this).serialize();
			const method = $(this).attr('method');
			const action = $(this).attr('action');
			const target = $(this).data('target');
			const command = $(this).data('command');
			const onSuccess = $(this).data('onSuccess');
			const submitButton = form.find('button')[0] || null;
			const inputWrappers = form.find('.form__fieldWrap')

			if (submitButton !== null) {
				submitButton.classList.add('loading')
				submitButton.disabled = true
			}

			// console.log('ajax:' + method + ' — ' + action);

			$.ajax({
				type: method,
				url: action,
				data: formData,
				async: true,
				success: function (data) {
					location.reload()
				},
				error: function (jqXHR, textStatus, errorThrown) {
					if (jqXHR && jqXHR.hasOwnProperty('responseJSON')) {
						let response = jqXHR.responseJSON
						if (response && response.errors) {
							let errors = response.errors
							inputWrappers.each((key, inputWrapper) => {
								let input = inputWrapper.getElementsByClassName('interactive')[0] || null
								if (input !== null) {
									let validateErrors = errors[input.getAttribute('name')] || null
									$(inputWrapper).find('span.form__label').each(function (validateSpanKey, validateSpan) {
										validateSpan.remove()
									})
									if (validateErrors !== null && validateErrors.length > 0) {
										inputWrapper.classList.add("error")

										validateErrors.forEach(function (inputErrorValue, inputErrorKey) {
											let validateSpan = document.createElement('span')
											validateSpan.className = "form__label";
											validateSpan.innerText = inputErrorValue

											inputWrapper.append(validateSpan)
										})
									} else {
										inputWrapper.classList.remove("error")
									}

								}
							})
						}
					}
				},
				complete: function (data) {
					if (submitButton !== null) {
						submitButton.classList.remove('loading')
						submitButton.disabled = false
					}
				}
			})
			return false
		}

		function initAjax() {
			$('.js-on-change-ajax').not('.js-on-change-ajax-inited').addClass('js-on-change-ajax-inited').on('change', ajax);
			$('.js-on-submit-ajax').not('.js-on-submit-ajax-inited').addClass('js-on-submit-ajax-inited').on('submit', ajax);
		}

		initAjax();

		document.body.addEventListener('initAjax', (e) => initAjax())

		document.body.addEventListener('regStudentFormSelectWithAjaxInit', (e) => {
			$(".register-user-form-js").each(function (formId, form) {
				let selectTeacher = form.getElementsByClassName('select-teacher-js')[0] || null
				let selectRegion = form.getElementsByClassName('select-region-js')[0] || null
				if (selectTeacher !== null && selectRegion !== null) {
					selectRegion.onchange = function () {
						let selectTeacherWrapper = selectTeacher.parentElement.parentElement
						if (this.value !== null && this.value !== 'null') {
							const SELECT_TEACHER_OPTION_CLASS = 'select2-results__option'
							// let selectTeacherOptions = selectTeacher.getElementsByClassName(SELECT_TEACHER_OPTION_CLASS)
							$('.select-teacher-js').empty()
							// for (let selectTeacherOption of selectTeacherOptions) {
							//     selectTeacherOption.remove()
							// }
							$.ajax({
								url: "api/v1/teachers?region_id=" + this.value,
								method: 'get',
								success: function (data) {
									// let option = document.createElement('option')
									// option.value = 0
									// option.innerText = 'Участие без учителя'
									// option.className = SELECT_TEACHER_OPTION_CLASS
									// selectTeacher.append(option)
									// if (data.data.length !== 0) {
									data.data.map(function (teacher) {
										let option = document.createElement('option')
										option.value = teacher.id
										option.innerText = teacher.name
										option.className = SELECT_TEACHER_OPTION_CLASS
										selectTeacher.append(option)
									})
									selectTeacherWrapper.classList.remove('hidden')
									// }
								},
								error: function (data) {
									selectTeacherWrapper.classList.add('hidden')
								}
							});
						} else {
							selectTeacherWrapper.classList.add('hidden')
						}
					}
				}
			})
		})
		const event = new Event('regStudentFormSelectWithAjaxInit');
		document.body.dispatchEvent(event)

	})


	isLoad = isLoad + 1;
	checkLoad();
})

if (!isReadyYT) {
	setTimeout(() => {
		let tag = document.createElement('script')
		tag.src = 'https://www.youtube.com/iframe_api'
		let firstScriptTag = document.getElementsByTagName('script')[0]
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
	}, 0)
}

function onYouTubeIframeAPIReady() {
	console.log("---------------YouTube API loaded and ready");
	isReadyYT = true
	$(document.body)
		.find('.youtube-js')
		.each(function () {
			const ytid = $(this).attr('data-key')
			const elid = $(this).attr('id')
			let player

			player = new YT.Player(elid, {
				videoId: ytid,
			})
		})
}

let modalsNameForCoockie = ['regteachervk', 'regteacher', 'regstudent', 'regstudentvk']

function setModalToCookie(modal) {
	if (modalsNameForCoockie.includes(modal.id)) {
		if (confirm('Регистрация не завершена. Вы уверены, что хотите закрыть форму?')) {
			let data = {
				'form_name': modal.id
			}
			let form = modal.getElementsByTagName("form")[0]
			form.querySelectorAll('input').forEach(input => {
				data[input.name] = input.value
			})
			form.querySelectorAll('select').forEach(input => {
				data[input.name] = input.value
			})
			let now = new Date();
			let time = now.getTime();
			let expireTime = time + 259199900000;
			now.setTime(expireTime);
			document.cookie = 'register_modal_form_data=' + JSON.stringify(data) + ';expires=' + now.toUTCString();
		} else {
			e.preventDefault()
		}
	}
}
